import { $host } from ".";

export const createExp = async (institute: string, date: string, description: string) => {
    const {data} = await $host.post('exp', {institute, date, description});
    return data;
};

export const fetchExps = async () => {
    const {data} = await $host.get('exp');
    return data;
};

export const updateExp = async (id: number, institute: string, date: string, description: string) => {
    const {data} = await $host.put('exp/' + id, {institute, date, description});
    return data;
};

export const deleteExp = async (id: number) => {
    await $host.delete('exp/' + id);
};