import { $host } from ".";

export const createAbout = async (about: FormData) => {
    const {data} = await $host.post('about', about);
    return data;
};

export const fetchAbout = async (id: number) => {
    const {data} = await $host.get('about/' + id);
    return data;
};

export const updateAbout = async (id: number, about: FormData) => {
    const {data} = await $host.put('about/' + id, about);
    return data;
};