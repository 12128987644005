import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom'
import {Modal, Button, Form, Col, Row} from 'react-bootstrap';
import { AxiosError } from 'axios';

import {updateAbout} from '../../http/aboutAPI';
import {IAboutDescription} from '../../types/types';
import {ABOUT_ROUTE} from '../../utils/consts';
import { selectFile } from '../../utils/someFunctions';

interface ModalEditAboutProps {
    show: boolean;
    onHide: () => void;
};


const ModalEditAbout: React.FC<ModalEditAboutProps> = ({show, onHide}) => {
    
    const [title, setTitle] = useState<string>('Меня зовут Алексей');
    const [photo, setPhoto] = useState<File | null>(null);
    const [description, setDescription] = useState<IAboutDescription[]>([
        {
            id: 1,
            description: "В 2008 окончил Московский Энергетический Институт на Радиотехническом факультете. На последнем курсе начал заниматься проектированием слаботочных систем. Успешно проработав в этой сфере, с позиции чертёжника вырос до главного инженера."
        },
        {
            id: 2,
            description: "С 2020 увлёкся программированием, выбрал frontend. Прошел длительное обучение на площадке Udemy. Развиваюсь в направлении Frontend (TypeScript, JavaScript, React, Redux, MobX) + FullStack (back на Node.js + Express / Nest). Есть опыт коммерческой разработки."
        },
        {
            id: 3,
            description: "Мои сильные стороны: хорошие коммуникативные навыки, аналитический склад ума, высокий уровень усидчивости, умение работать в команде."
        },
        {
            id: 4,
            description: "У меня огромное желание обучаться новому и совершенствовать свои hard skills во фронтенде. Мои увлечения: занимаюсь спортом, люблю путешествовать."
        },
    ]);
    const navigate = useNavigate();

    const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setPhoto(selectFile(e));
    };

    const addDescription = () => {
        setDescription([...description, {description: '', id: Date.now()}]);
    };

    const removeDescription = (id: number) => {
        setDescription(description.filter(item => item.id !== id));
    };

    const changeDescription = (key: string, value: string, id: number) => {
        setDescription(description.map(item => item.id === id ? {...item, [key]: value} : item));
    };

    const editAbout = async (e: React.FormEvent) => {
        if (!description.length) {
            return alert('Необходимо запольнить хотя бы 1 описание');
        }
        if (!title.trim()) {
            return alert('Заголовок необходимо заполнить');
        }
        if (!photo) {
            return alert('Изображение необходимо загрузить');
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('photo', photo);
        formData.append('description', JSON.stringify(description));

        try {
            await updateAbout(1, formData).then(() => navigate(ABOUT_ROUTE));
            
        } catch(err: unknown) {
            const error = err as AxiosError;
            alert(JSON.parse(error.request.response).message);
        }        
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Изменить описание
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        className="mt-3"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        placeholder="Заголовок"
                    />

                    <label htmlFor="file" className="mt-3">Загрузи изображение</label>       
                    <Form.Control                        
                        type="file"
                        accept="image/*"
                        onChange={uploadFile}
                    />  

                    <Button 
                        className="mt-3 w-100"
                        variant="outline-secondary"
                        onClick={addDescription}
                        >Добавить описание о себе
                    </Button>

                    {description.map(item =>
                        <Row className="mt-3" key={item.id}>
                            <Col md={10}>
                                <Form.Control as="textarea"
                                    value={item.description}
                                    onChange={e => changeDescription('description', e.target.value, item.id)}
                                    placeholder="Введи описание"
                                    maxLength={700}
                                />
                            </Col>
                            <Col md={2}>
                                <Button 
                                    variant="outline-danger"
                                    onClick={() => removeDescription(item.id)}
                                    >Удалить
                                </Button>
                            </Col>
                        </Row>    
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-primary"} onClick={editAbout}>Обновить</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEditAbout;