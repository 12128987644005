import React, {useState, useEffect} from 'react';

import CUExp from '../CreateUpdate/CUExp';
import {IExperience, IEducation} from '../../types/types';
import { updateEdu } from '../../http/eduAPI';
import { updateExp } from '../../http/expAPI';

interface ModalEditExpProps {
    show: boolean;
    onHide: () => void;
    item: IExperience | IEducation;
    exp: boolean;
};


const ModalEditExp: React.FC<ModalEditExpProps> = ({show, onHide, item, exp}) => {
    const [institute, setInstitute] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        setInstitute(item.institute);
        setDate(item.date);
        setDescription(item.description);
    }, [show]);
    
    return (
        <CUExp 
            id={item.id}
            institute={institute}
            date={date}
            description={description}
            setInstitute={setInstitute}
            setDate={setDate}
            setDescription={setDescription}
            // @ts-ignore
            handler={exp ? updateExp : updateEdu}
            title={`Обновить ${exp ? 'опыт' : 'образование'}`}
            btnName='Обновить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalEditExp;