import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Modal, Button, Form} from 'react-bootstrap';
import { AxiosError } from 'axios';

import { updateContact } from '../../http/contactsAPI';
import {CONTACTS_ROUTE} from '../../utils/consts';
import { selectFile } from '../../utils/someFunctions';

interface ModalEditContactsProps {
    show: boolean;
    onHide: () => void;
};


const ModalEditContacts: React.FC<ModalEditContactsProps> = ({show, onHide}) => {
    const [phone, setPhone] = useState<string>('+7 985 145 17 48');
    const [email, setEmail] = useState<string>('fischer84@mail.ru');
    const [telegram, setTelegram] = useState<string>('https://t.me/Krnv1');
    // @ts-ignore
    const [photo, setPhoto] = useState<File>(null);
    const navigate = useNavigate();

    const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setPhoto(selectFile(e));
    };

    const editContacts = async (e: React.FormEvent) => {
        if (!phone.trim() || !email.trim() || !telegram.trim()) {
            return alert('Все поля обязательны для заполнения');
        }
        if (!photo) {
            return alert('Изображение необходимо загрузить');
        }

        const formData = new FormData();
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('telegram', telegram);
        formData.append('photo', photo);

        try {
            await updateContact(1, formData).then(() => {
                // await updateContact(1, phone, email, telegram).then(() => {
                navigate(CONTACTS_ROUTE);
            });
            
        } catch(err: unknown) {
            const error = err as AxiosError;
            alert(JSON.parse(error.request.response).message);
        }        
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Изменить контактные данные
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Телефон</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            placeholder="Новый телефон" 
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text" 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Новый email" 
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Telegram</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={telegram}
                            onChange={e => setTelegram(e.target.value)}
                            placeholder="Новый телеграм" 
                        />
                        <label htmlFor="file" className="mt-3">Загрузи изображение</label>       
                        <Form.Control                        
                            type="file"
                            accept="image/*"
                            onChange={uploadFile}
                        /> 
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-primary"} onClick={editContacts}>Обновить</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEditContacts;