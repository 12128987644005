import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';

import {PORTFOLIO_ROUTE} from '../../utils/consts';
import { selectFile } from '../../utils/someFunctions';

interface CUProjectProps {
    id: number;
    title: string;
    site: string;
    git: string;
    stack: string;
    description: string;
    cover: File | null;
    setTitle: (title: string) => void;
    setSite: (site: string) => void;
    setGit: (git: string) => void;
    setStack: (stack: string) => void;
    setDescription: (description: string) => void;
    setCover: (cover: File | null) => void;
    handler: (id: number, exp: FormData) => Promise<unknown>;
    modalTitle: string;
    btnName: string;
    show: boolean;
    onHide: () => void;
};

const CUProject: React.FC<CUProjectProps> = ({id, title, site, git, stack, description, cover, setTitle, setSite, setGit, setStack, setDescription, setCover, handler, modalTitle, btnName, show, onHide}) => {

    const navigate = useNavigate();

    const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setCover(selectFile(e));
    };

    const onClick = () => {
        if (!title.trim() || !site.trim() || !git.trim() || !stack.trim() || !description.trim()) {
            return alert('Все поля обязательны для заполнения');
        }

        if (!cover) {
            return alert('Изображение необходимо загрузить');
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('site', site);
        formData.append('git', git);
        formData.append('stack', stack);
        formData.append('description', description);
        formData.append('cover', cover);

        if (btnName === 'Добавить') {
            // @ts-ignore 
            handler(formData)
                .then(() => {
                    setTitle('');
                    setSite('');
                    setGit('');
                    setStack('');
                    setDescription('');
                    setCover(null);
                    navigate(PORTFOLIO_ROUTE);
                })
                .catch(err => alert(err.response.data.message));
        } else {
            handler(id, formData)
                .then(() => {
                    setTitle('');
                    setSite('');
                    setGit('');
                    setStack('');
                    setDescription('');
                    setCover(null);
                    onHide();
                })
                .catch(err => alert(err.response.data.message));
        }
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        className="mt-3"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        placeholder="Название"
                    />
                    <Form.Control
                        className="mt-3"
                        value={site}
                        onChange={e => setSite(e.target.value)}
                        placeholder="Сайт"
                    />
                    <Form.Control
                        className="mt-3"
                        value={git}
                        onChange={e => setGit(e.target.value)}
                        placeholder="GitHub"
                    />
                    <Form.Control as="textarea"
                        className="mt-3"
                        value={stack}
                        onChange={e => setStack(e.target.value)}
                        placeholder="Stack"
                        maxLength={700}
                    />
                    <Form.Control as="textarea"
                        className="mt-3"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        placeholder="Описание"
                        maxLength={700}
                    />
                    <label htmlFor="file" className="mt-3">Загрузи изображение</label>       
                    <Form.Control                        
                        type="file"
                        accept="image/*"
                        onChange={uploadFile}
                    /> 
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={btnName === 'Добавить' ? "outline-success" : "outline-primary"} onClick={onClick}>{btnName}</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CUProject;