import { $host } from ".";

export const createEdu = async (institute: string, date: string, description: string) => {
    const {data} = await $host.post('edu', {institute, date, description});
    return data;
};

export const fetchEdus = async () => {
    const {data} = await $host.get('edu');
    return data;
};

export const updateEdu = async (id: number, institute: string, date: string, description: string) => {
    const {data} = await $host.put('edu/' + id, {institute, date, description});
    return data;
};

export const deleteEdu = async (id: number) => {
    await $host.delete('edu/' + id);
};