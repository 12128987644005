import { $host } from ".";

export const createSkill = async (skill: FormData) => {
    const {data} = await $host.post('skills', skill);
    return data;
};

export const fetchSkills = async () => {
    const {data} = await $host.get('skills');
    return data;
};

export const deleteSkill = async (id: number) => {
    await $host.delete('skills/' + id);
};