import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Modal, Button, Form} from 'react-bootstrap';
import { AxiosError } from 'axios';

import {createSkill} from '../../http/skillsAPI';
import {SKILLS_ROUTE} from '../../utils/consts';
import { selectFile } from '../../utils/someFunctions';

interface ModalAddSkillProps {
    show: boolean;
    onHide: () => void;
};


const ModalAddSkill: React.FC<ModalAddSkillProps> = ({show, onHide}) => {
    const navigate = useNavigate();
    const [title, setTitle] = useState<string>('');
    const [icon, setIcon] = useState<File | null>(null);

    const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setIcon(selectFile(e));
    };

    const addSkill = () => {
        if (!icon) {
            return alert('Изображение необходимо загрузить');
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('icon', icon);

        try {
            createSkill(formData).then(() => {
                setTitle('');
                // @ts-ignore
                setIcon(null);
                navigate(SKILLS_ROUTE);
            });

        } catch(err: unknown) {
            const error = err as AxiosError;
            alert(JSON.parse(error.request.response).message);
        }         
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить навык
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        className="mt-3"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        placeholder="Название навыка"
                    />
                    <label htmlFor="file" className="mt-3">Загрузи изображение</label>       
                    <Form.Control                        
                        type="file"
                        accept="image/*"
                        onChange={uploadFile}
                    />                     
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-success"} onClick={addSkill}>Добавить</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAddSkill;