import React, {useState} from 'react';

import { createProject } from '../../http/projectsAPI';
import CUProject from '../CreateUpdate/CUProject';

interface ModalAddProjectProps {
    show: boolean;
    onHide: () => void;
};


const ModalAddProject: React.FC<ModalAddProjectProps> = ({show, onHide}) => {
    const [title, setTitle] = useState<string>('');
    const [site, setSite] = useState<string>('');
    const [git, setGit] = useState<string>('');
    const [stack, setStack] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [cover, setCover] = useState<File | null>(null);
    
    return (
        <CUProject 
            id={0}
            title={title}
            site={site}
            git={git}
            stack={stack}
            description={description}
            cover={cover}
            setTitle={setTitle}
            setSite={setSite}
            setGit={setGit}
            setStack={setStack}
            setDescription={setDescription}
            setCover={setCover}
            // @ts-ignore
            handler={createProject}
            modalTitle='Добавить проект'
            btnName='Добавить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalAddProject;