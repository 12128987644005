import { $host } from ".";

export const createDescr = async (descr: FormData) => {
    const {data} = await $host.post('descr', descr);
    return data;
};

export const fetchAllDescrs = async () => {
    const {data} = await $host.get('descr');
    return data;
};

export const fetchOneDescr = async (id: number) => {
    const {data} = await $host.get('descr/' + id);
    return data;
};

export const updateDescr = async (id: number, descr: FormData) => {
    const {data} = await $host.put('descr/' + id, descr);
    return data;
};