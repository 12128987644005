import {ABOUT_ROUTE, ADMIN_ROUTE, CONTACTS_ROUTE, MAIN_ROUTE, POLICY_ROUTE, PORTFOLIO_ROUTE, SKILLS_ROUTE} from './utils/consts';
import {AboutPage, AdminPage, ContactsPage, MainPage, PolicyPage, PortfolioPage, SkillsPage} from './pages';

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: AdminPage
    }
];

export const publicRoutes = [
    {
        path: ABOUT_ROUTE,
        Component: AboutPage
    },
    {
        path: CONTACTS_ROUTE,
        Component: ContactsPage
    },
    {
        path: MAIN_ROUTE,
        Component: MainPage
    },
    {
        path: POLICY_ROUTE,
        Component: PolicyPage
    },
    {
        path: PORTFOLIO_ROUTE,
        Component: PortfolioPage
    },
    {
        path: SKILLS_ROUTE,
        Component: SkillsPage
    }
];