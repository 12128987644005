import React, {useState, useEffect, useContext} from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ABOUT_ROUTE, ADMIN_ROUTE, CONTACTS_ROUTE, MAIN_ROUTE, PORTFOLIO_ROUTE, SKILLS_ROUTE } from "../../utils/consts";
import { Context } from '../..';

import './header.sass';


const Header: React.FC = () => {
    const location = useLocation();
    const {admin} = useContext(Context);
    const [classMenu, setClassMenu] = useState<string>('');

    useEffect(() => {
        setClassMenu('');
    }, [location.pathname]);

    const menuHandler = () => {
        classMenu === '' ? setClassMenu('open-menu') : setClassMenu('');
    };

    return (
        <div className='header'>
            <div className={"header__menu-burger" + ' ' + classMenu} onClick={() => menuHandler()}>
                <span></span>
            </div>

            <nav className={'header__nav' + ' ' + classMenu}>
                <ul className="header__menu">
                    <li className="header__menu_item">
                        <NavLink to={MAIN_ROUTE} className={location.pathname === MAIN_ROUTE ? "active" : ''} >
                            ГЛАВНАЯ
                        </NavLink>
                    </li>
                    <li className="header__menu_item">
                        <NavLink to={PORTFOLIO_ROUTE} className={location.pathname === PORTFOLIO_ROUTE ? "active" : ''} >
                            ПОРТФОЛИО
                        </NavLink>
                    </li>
                    <li className="header__menu_item">
                        <NavLink to={SKILLS_ROUTE} className={location.pathname === SKILLS_ROUTE ? "active" : ''} >
                            НАВЫКИ
                        </NavLink>
                    </li>
                    <li className="header__menu_item">
                        <NavLink to={ABOUT_ROUTE} className={location.pathname === ABOUT_ROUTE ? "active" : ''} >
                            ОБО МНЕ
                        </NavLink>
                    </li>
                    <li className="header__menu_item">
                        <NavLink to={CONTACTS_ROUTE} className={location.pathname === CONTACTS_ROUTE ? "active" : ''} >
                            КОНТАКТЫ
                        </NavLink>
                    </li>
                    {admin.isAuth &&
                        <li className="header__menu_item">
                            <NavLink to={ADMIN_ROUTE} className={location.pathname === ADMIN_ROUTE ? "active" : ''} >
                                АДМИНКА
                            </NavLink>
                        </li>
                    }
                </ul>
            </nav>
        </div>
    );
};

export default Header;