import { $host } from ".";

export const createContact = async (contact: FormData) => {
    const {data} = await $host.post('contacts', contact);
    return data;
};

export const fetchOneContact = async (id: number) => {
    const {data} = await $host.get('contacts/' + id);
    return data;
};

export const updateContact = async (id: number, contact: FormData) => {
    const {data} = await $host.put('contacts/' + id, contact);
    return data;
};