import React, {useState} from 'react';
import {Modal, Button, Form, Col, Row} from 'react-bootstrap';
import { AxiosError } from 'axios';

import { createDescr } from '../../http/aboutDescrAPI'; 
import {IAboutDescription} from '../../types/types';

interface ModalAddAboutDescrProps {
    show: boolean;
    onHide: () => void;
};


const ModalAddAboutDescr: React.FC<ModalAddAboutDescrProps> = ({show, onHide}) => {
    
    const [description, setDescription] = useState<IAboutDescription[]>([]);

    const addDescription = () => {
        setDescription([...description, {description: '', id: Date.now()}]);
    };

    const removeDescription = (id: number) => {
        setDescription(description.filter(item => item.id !== id));
    };

    const changeDescription = (key: string, value: string, id: number) => {
        setDescription(description.map(item => item.id === id ? {...item, [key]: value} : item));
    };

    const addDescr = async (e: React.FormEvent) => {
        if (!description.length) {
            return alert('Необходимо запольнить хотя бы 1 описание');
        }

        const formData = new FormData();
        formData.append('description', JSON.stringify(description));

        try {
            await createDescr(formData).then(() => onHide());
            
        } catch(err: unknown) {
            const error = err as AxiosError;
            alert(JSON.parse(error.request.response).message);
        }        
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Изменить описание
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Button 
                        className="mt-3 w-100"
                        variant="outline-secondary"
                        onClick={addDescription}
                        >Добавить описание о себе
                    </Button>

                    {description.map(item =>
                        <Row className="mt-3" key={item.id}>
                            <Col md={10}>
                                <Form.Control as="textarea"
                                    value={item.description}
                                    onChange={e => changeDescription('description', e.target.value, item.id)}
                                    placeholder="Введи описание"
                                    maxLength={700}
                                />
                            </Col>
                            <Col md={2}>
                                <Button 
                                    variant="outline-danger"
                                    onClick={() => removeDescription(item.id)}
                                    >Удалить
                                </Button>
                            </Col>
                        </Row>    
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-primary"} onClick={addDescr}>Добавить</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAddAboutDescr;