import React, {useState, useEffect} from 'react';

import { updateProject } from '../../http/projectsAPI';
import CUProject from '../CreateUpdate/CUProject';
import {IProject} from '../../types/types';

interface ModalEditProjectProps {
    show: boolean;
    onHide: () => void;
    project: IProject;
};


const ModalEditProject: React.FC<ModalEditProjectProps> = ({show, onHide, project}) => {
    const [title, setTitle] = useState<string>('');
    const [site, setSite] = useState<string>('');
    const [git, setGit] = useState<string>('');
    const [stack, setStack] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [cover, setCover] = useState<File | null>(null);

    useEffect(() => {
        setTitle(project.title);
        setSite(project.site);
        setGit(project.git);
        setStack(project.stack);
        setDescription(project.description);
    }, [show]);
    
    return (
        <CUProject 
            id={project.id}
            title={title}
            site={site}
            git={git}
            stack={stack}
            description={description}
            cover={cover}
            setTitle={setTitle}
            setSite={setSite}
            setGit={setGit}
            setStack={setStack}
            setDescription={setDescription}
            setCover={setCover}
            // @ts-ignore
            handler={updateProject}
            modalTitle='Обновить проект'
            btnName='Обновить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalEditProject;