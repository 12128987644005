import React, {useState} from 'react';

import CUExp from '../CreateUpdate/CUExp';
import { createEdu } from '../../http/eduAPI';
import { createExp } from '../../http/expAPI';

interface ModalAddExpProps {
    show: boolean;
    onHide: () => void;
    exp: boolean;
};


const ModalAddExp: React.FC<ModalAddExpProps> = ({show, onHide, exp}) => {
    const [institute, setInstitute] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    
    return (
        <CUExp 
            id={0}
            institute={institute}
            date={date}
            description={description}
            setInstitute={setInstitute}
            setDate={setDate}
            setDescription={setDescription}
            // @ts-ignore
            handler={exp ? createExp : createEdu}
            title={`Добавить ${exp ? 'опыт' : 'образование'}`}
            btnName='Добавить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalAddExp;