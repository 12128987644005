import { $host } from ".";

export const createProject = async (project: FormData) => {
    const {data} = await $host.post('projects', project);
    return data;
};

export const fetchProjects = async () => {
    const {data} = await $host.get('projects');
    return data;
};

export const fetchOneProject = async (id: number) => {
    const {data} = await $host.get('projects/' + id);
    return data;
};

export const updateProject = async (id: number, project: FormData) => {
    const {data} = await $host.put('projects/' + id, project);
    return data;
};

export const deleteProject = async (id: number) => {
    await $host.delete('projects/' + id);
};