import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Form, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import { IContacts } from '../../types/types';
import { fetchOneContact } from '../../http/contactsAPI';
import { createFeedback } from '../../http/feedbackAPI';
import { POLICY_ROUTE } from '../../utils/consts';
import { photo } from '../../assets/contacts';
import { validEmail } from '../../utils/someFunctions';

import './contactsPage.sass';


const ContactsPage: React.FC = () => {
    const navigate = useNavigate();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [checked, setChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [contacts, setContacts] = useState<IContacts>({
        id: 1,
        photo: photo,
        phone: '+7 (985) 145 17 48',
        email: 'fischer84@mail.ru',
        telegram: 'https://t.me/Krnv1'
    });

    useEffect(() => {
        fetchOneContact(1)
            .then(data => {
                setContacts(data);
                setLoading(false);
            })
            .catch(err => console.log(JSON.parse(err.request.response).message));
    }, []);

    const handleCheck = () => {
        setChecked(!checked);
    };

    const onSubmit = () => {
        if (!name.trim() || !email.trim() || !text.trim()) {
            return alert('Все поля обязательны для заполнения!');
        }
        if (!validEmail.test(email)) {
            return alert('Некорректный email');
        }
        if (!checked) {
            return alert('Необходимо согласие с политикой конфиденциальности');
        }

        createFeedback(name, email, text)
            .then(() => alert('Сообщение отправлено!'))
            .catch(() => alert('Произошла ошибка'));
    };


    return (
        <div>
            <Helmet>
                <title>Портфолио | Контакты</title>
                <meta name="description" content="Портфолио | Контакты" />
            </Helmet>

            <Container className='contacts' >
                <div className="contacts__wrapper">
                    {loading ?
                        <img src={contacts.photo} className='contacts__img' alt="" />
                        :
                        <img src={process.env.REACT_APP_API_URL + contacts.photo} className='contacts__img' alt="" />
                    }
                    <div className='contacts__text'>
                        <div className="contacts__title">Свяжитесь со мной</div>
                        <div className="contacts__subtitle">любым удобным для вас способом:</div>
                        <div className="contacts__phone">{contacts.phone}</div>
                        <div className="contacts__email">
                            <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
                        </div>
                        <a href={contacts.telegram} target="_blank">
                            <i className="bi bi-telegram contacts__telegram"></i>
                        </a>
                        <div className="contacts__subtitle_sub">или оставьте ваши данные и я сам вам напишу:</div>

                        <Form>
                            <label htmlFor="name" className="mt-3">Ваше имя</label> 
                            <Form.Control
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder="Ваше имя"
                            />
                            <label htmlFor="email" className="mt-3">Ваша почта</label> 
                            <Form.Control
                                value={email}
                                type="email"
                                onChange={e => setEmail(e.target.value)}
                                placeholder="Ваша почта"
                            />
                            <label htmlFor="text" className="mt-3">Ваше сообщение</label> 
                            <Form.Control as="textarea"
                                value={text}
                                onChange={e => setText(e.target.value)}
                                placeholder="Ваше сообщение"
                                maxLength={700}
                            />
                        </Form>
                        <div className="contacts__triggers">
                            <Button variant={"outline-light"} onClick={onSubmit}>Отправить сообщение</Button>
                            <div className="contacts__policy">
                                <input required type="checkbox" checked={checked} onChange={handleCheck} />
                                <span>Я согласен(а) с <a href="#" onClick={() => navigate(POLICY_ROUTE)} >политикой конфиденциальности</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ContactsPage;