// @ts-ignore
export const sort = (items) => {        
    let sortItems = [];

    sortItems = [...items].sort((a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1;
    });

    return sortItems;
};

export const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const files: FileList | null = e.target.files;
    if (files) {
        if (files[0].size > 1048576) {
            return alert('Изображение должно быть менее 1мб')
        }
        // @ts-ignore 
        const fileExtension: string = files[0].name.split(".").at(-1);
        const allowedFileTypes: string[] = ["jpg", "jpeg", "png", "webp"];
        if (!allowedFileTypes.includes(fileExtension)) {
            return alert(`Расширение файла не поддерживается. Допустымые расширения: ${allowedFileTypes.join(", ")}`);
        }

        return files[0];
    } else {
        return null;
    }
};

export const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;