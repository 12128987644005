import React, {useContext} from 'react';
import { Card } from 'react-bootstrap';

import { IProject } from '../../types/types';
import { Context } from '../..';

import './projectItem.sass';

interface ProjectItemProps {
    item: IProject;
    onDelete: (item: IProject) => void;
    onEdit: (item: IProject) => void;
};


const ProjectItem: React.FC<ProjectItemProps> = ({item, onDelete, onEdit}) => {
    const {admin} = useContext(Context);

    return (
        <Card className="project-item" >
            <a href={item.site} target="_blank" >
                <img className="project-item__img" src={process.env.REACT_APP_API_URL + item.cover} alt={item.title} />
            </a>
            <div>
                <div className="project-item__title">{item.title}</div>
                <div className="project-item__links">
                    <a href={item.site} target="_blank" >site</a>
                    <a href={item.git} target="_blank" >github</a>
                </div>
                <div className="project-item__stack"><b>Stack:</b> {item.stack}</div>
                <div className="project-item__description"><b>Описание:</b> {item.description}</div>
                {admin.isAuth && 
                    <div>
                        <i className="bi bi-pencil-fill list-item__icon" onClick={() => onEdit(item)}></i>
                        <i className="bi bi-trash3-fill list-item__icon" onClick={() => onDelete(item)}></i>
                    </div>
                }
            </div>
        </Card>      
    );
};

export default ProjectItem;