import React, {useState, useContext, useEffect} from 'react';
import {Helmet} from "react-helmet";

import ModalLogin from '../../components/Modals/ModalLogin';
import { Context } from '../..';

import './mainPage.sass';


const MainPage: React.FC = () => {
    const {admin} = useContext(Context);
    const [visible, setVisible] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setToggle(true);
        }, 5000);
    }, []);

    const loginHandler = () => {
        if (!admin.isAuth) {
            setVisible(true);
        }        
    };


    return (
        <div className='main'>
            <Helmet>
                <title>Портфолио | Корнеев Алексей</title>
                <meta name="description" content="Портфолио | Корнеев Алексей" />
            </Helmet>

            <h1 className="main__typewriter"><span onClick={() => loginHandler()} >F</span>rontend developer</h1>
            {toggle && 
                <div className="main__text">
                    <div>Данный сайт - fullstack app:</div>
                    <div><b>frontend:</b> TypeScript, React 18, SASS, BEM, React bootstrap, MobX</div>
                    <div><b>backend:</b> Node.js, Nest.js, Sequelize, MySQL</div>
                </div>
            }
            <ModalLogin show={visible} onHide={() => setVisible(false)} />  
        </div>
    );
};

export default MainPage;