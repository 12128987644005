import React, {useState, useEffect} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import List from '../../components/List/List';
import ProjectItem from '../../components/ProjectItem/ProjectItem';
import ModalEditProject from '../../components/Modals/ModalEditProject';
import {sort} from '../../utils/someFunctions';
import { IProject } from '../../types/types';
import { fetchProjects, deleteProject } from '../../http/projectsAPI';

import './portolioPage.sass';


const PortfolioPage: React.FC = () => {

    const [projects, setProjects] = useState<IProject[]>([]);
    const [sortProjects, setSortProjects] = useState<IProject[]>([]);
    const [project, setProject] = useState<IProject>({} as IProject);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [toggle, setToggle] = useState<boolean>(false);

    useEffect(() => {
        fetchProjects()
            .then(data => {
                setProjects(data);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                console.log(JSON.parse(err.request.response).message);
            });
    }, [toggle, visible]);

    useEffect(() => {
        setSortProjects(sort(projects));
    }, [projects]);

    const removeItem = (item: IProject) => {
        if (window.confirm(`Ты действительно хочешь удалить проект "${item.title}"?`)) {
            deleteProject(item.id).then(() => setToggle(!toggle));
        }    
    };

    const editItem = (item: IProject) => {
        setProject(item);
        setVisible(true);
    };

    return (
        <Container className='portfolio'>
            <Helmet>
                <title>Портфолио | Проекты</title>
                <meta name="description" content="Портфолио | Проекты" />
            </Helmet>

            {loading ?
                <Spinner animation="border" variant="light" />
                :
                <List 
                    items={sortProjects}
                    renderItem={(item: IProject) => 
                        <ProjectItem 
                            onDelete={() => removeItem(item)} 
                            onEdit={() => editItem(item)} 
                            item={item} 
                            key={item.id}
                        />
                    } 
                />
            }
            <ModalEditProject show={visible} onHide={() => setVisible(false)} project={project} />
        </Container>
    );
};

export default PortfolioPage;