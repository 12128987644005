import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';

import {ABOUT_ROUTE} from '../../utils/consts';

interface CUExpProps {
    id: number;
    institute: string;
    date: string;
    description: string;
    setInstitute: (institute: string) => void;
    setDate: (date: string) => void;
    setDescription: (description: string) => void;
    handler: (id: number, institute: string, date: string, description: string) => Promise<unknown>;
    title: string;
    btnName: string;
    show: boolean;
    onHide: () => void;
};

const CUExp: React.FC<CUExpProps> = ({id, institute, date, description, setInstitute, setDate, setDescription, handler, title, btnName, show, onHide}) => {

    const navigate = useNavigate();

    const onClick = () => {
        if (!institute.trim() || !date.trim() || !description.trim()) {
            return alert('Все поля обязательны для заполнения');
        }

        if (btnName === 'Добавить') {
            // @ts-ignore 
            handler(institute, date, description)
                .then(() => {
                    setInstitute('');
                    setDate('');
                    setDescription('');
                    navigate(ABOUT_ROUTE);
                })
                .catch(err => alert(err.response.data.message));
        } else {
            handler(id, institute, date, description)
                .then(() => {
                    setInstitute('');
                    setDate('');
                    setDescription('');
                    onHide();
                })
                .catch(err => alert(err.response.data.message));
        }
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        className="mt-3"
                        value={institute}
                        onChange={e => setInstitute(e.target.value)}
                        placeholder="Учреждение"
                    />
                    <Form.Control
                        className="mt-3"
                        value={date}
                        onChange={e => setDate(e.target.value)}
                        placeholder="Временной период"
                    />
                    <Form.Control as="textarea"
                        className="mt-3"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        placeholder="Описание"
                        maxLength={500}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={btnName === 'Добавить' ? "outline-success" : "outline-primary"} onClick={onClick}>{btnName}</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CUExp;