import React, {useState, useEffect} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import { ISkill } from '../../types/types';
import { fetchSkills } from '../../http/skillsAPI';
import SkillCard from '../../components/SkillCard/SkillCard';
import ParticlesFon from '../../components/Particles/ParticlesFon';

import './skillsPage.sass';


const SkillsPage: React.FC = () => {
    const [skills, setSkills] = useState<ISkill[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [toggle, setToggle] = useState<boolean>(false);

    useEffect(() => {
        fetchSkills()
            .then( data => {
                setSkills(data);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                console.log(JSON.parse(err.request.response).message);
            });
    }, [toggle]);
    

    return (
        <div>
            <Helmet>
                <title>Портфолио | Навыки</title>
                <meta name="description" content="Портфолио | Навыки" />
            </Helmet>

            <ParticlesFon />
                <Container className='skills' >
                    {loading ?
                        <Spinner animation="border" variant="light" />
                        :
                        skills.map(skill =>
                            <SkillCard 
                                icon={skill.icon} 
                                title={skill.title} 
                                id={skill.id} 
                                toggle={toggle} 
                                setToggle={setToggle} 
                                key={skill.id} 
                            />
                        )
                    }
                </Container>
        </div>
    );
};

export default SkillsPage;